import type { AppConfig } from '@tsTypes/api/AppConfig';

import APP_MODES from '@constants/appModes';
import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getAppConfig = async () => {
  const { data } = await axios.get<AppConfig>(ENDPOINT.CONFIG);

  const { catering, shop } = data.modules.AppMode;

  const isDietMini = catering === APP_MODES.MINI;
  const isDietFull = catering === APP_MODES.FULL;
  const isShop = shop === APP_MODES.DISHES;
  const isRestaurant = shop === APP_MODES.RESTAURANT;
  const isDiet = isDietFull || isDietMini;
  const isOnlyDiet = !isShop && isDiet;
  const isOnlyShop = isShop && !isDiet;
  const isDietShop = isShop && isDiet;

  return {
    ...data,
    appMode: {
      isDiet,
      isDietFull,
      isDietMini,
      isDietShop,
      isOnlyDiet,
      isOnlyShop,
      isRestaurant,
      isShop,
    },
  };
};

export default getAppConfig;
